export const SET_USER = 'SET_USER';
export const SET_MODE = 'SET_MODE';
export const SET_RAIN = 'SET_RAIN';
export const SET_MOOD = 'SET_MOOD';
export const SET_VOLUME = 'SET_VOLUME';

export const LIST_ADD = 'LIST_ADD';
export const LIST_REMOVE = 'LIST_REMOVE';
export const LIST_ADD_DONE = 'LIST_ADD_DONE';
export const LIST_REMOVE_DONE = 'LIST_REMOVE_DONE';
